import React from 'react';
import {withRouter, Redirect} from 'react-router-dom';
import { AppSettings } from '../../config/app-settings.js';
import RegisterBG from '../../assets/img/bg/bg-ibbc.jpg';
import withNotification from '../../components/HOCNotification/HOCNotification.component';
import Services from '../../services/service.js';
import RegForm from './RegForm';

class Register extends React.Component {
  static contextType = AppSettings;

  constructor(props) {
    super(props);

    this.state = {
      pageError: false,
      redirect: false,
      event: this.props.match.params?.event? this.props.match.params.event : null,
      eventData: {},
      loading: true
    };
    this.getEvent = this.getEvent.bind(this);
  }

  componentDidMount() {
    this.context.handleSetAppSidebarNone(true);
    this.context.handleSetAppHeaderNone(true);
    this.context.handleSetAppContentClass('p-0');
    this.getEvent();
  }

  componentWillUnmount() {
    this.context.handleSetAppSidebarNone(false);
    this.context.handleSetAppHeaderNone(false);
    this.context.handleSetAppContentClass('');
  }

  getEvent = async () => {
    const param = { name: this.state.event };
    await Services.getEventDetail(param)
      .then((res) => {
        this.setState({eventData: res, loading: false});
      })
      .catch((err) => {
        console.log('event not found', err);
        this.setState({pageError: true, loading: false});
      });
  };

  render() {
    if(this.state.pageError){
      return <Redirect to='/'/>
    }

    return (
      <>
        {!this.state.loading && 
          <div className="register register-with-news-feed">
            <div className="news-feed">
              <div
                className="news-image"
                style={{
                  backgroundImage: `url(${RegisterBG})`,
                  backgroundPosition: 'left'
                }}
              ></div>
              {/* <div className="news-caption">
                <h4 className="caption-title">
                  <b>Independent Race</b> 2022
                </h4>
                <p>
                  Carrefour Kiaracondong Bandung Jawa Barat | 06 Agustus 2022
                </p>
              </div> */}
            </div>
            <div className="register-container">
              <div className="register-header mb-25px h1">
                <div className="mb-1">Registrasi</div>
              </div>
              <div className="register-content">
                <RegForm toggleNotification={this.props.toggleNotification} eventData={this.state.eventData}/>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

export default withRouter(withNotification(Register));
