import api from '../config/api';
const TOKEN = localStorage.getItem('token');
const header = TOKEN ? {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer ' + TOKEN
} : {'Content-Type': 'application/json'};

const API = new api({headers: header});
const APIUpload = new api({headers: {...header, 'Content-Type': 'multipart/form-data'}});

//AUTH API
const AuthAPI = {
  postLogin: (payload) => API.post('auth/login', payload),
  postForgotPassword: (payload) => API.post('auth/forgot-password', payload),
  postVerifyPassword:(payload) => API.post('/auth/forgot-password/verify', payload),
  // getGetMenu: (payload) => API.get('/menu-access', payload),
};


const ParticipantAPI = {
  postParticipant: (payload) => APIUpload.post('registration', payload),
};

const ParamAPI = {
  getList: (params) => API.get('parameter', {params}),
  // getAllParamList: (payload) => API.get('parameter-list', {params: payload}),
  // getParamDetail: (payload) => API.get(`parameter-detail?paramId=${payload}`),
  // postParameter: (payload) => API.post('parameter-create', payload),
  // updateParamValue: (payload, param) => API.put(`parameter-update`, payload, param),
};

const EventAPI = {
  getEventDetail: (params) => API.get('event', {params})
}


const Services = {
  ...AuthAPI,
  ...ParticipantAPI,
  ...ParamAPI,
  ...EventAPI,
};

export default Services;
