export const API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://13.213.70.208';
export const TIMEOUT = process.env.REACT_APP_API_TIMEOUT ? process.env.REACT_APP_API_TIMEOUT : 30000;

export const RESPONSE = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  INVALID: 'INVALID',
  ADDED: 'ADDED',
  INFO: 'INFO',
};
