import React from 'react';
import {ReactNotifications, Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import {RESPONSE} from '../../config/constants';

const {SUCCESS, FAILED, INVALID} = RESPONSE;

const withNotification = (WrappedComponent) => (props) => {

  const addNotification = (
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition
  ) => {
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: 'top',
      container: notificationPosition,
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: { duration: 2000 },
    });
  };

  const toggleNotification = (
    (action, msg = '') => {
      switch (action) {
        case SUCCESS:
          addNotification('success', 'Berhasil', msg, 'top-right');
          break;
        case FAILED:
          addNotification('danger', 'Gagal', msg, 'top-right');
          break;
        case INVALID:
          addNotification('info', 'Perhatian', msg, 'top-right');
          break;
        default:
          break;
      }
    }
  );

  return (
    <div>
      <ReactNotifications />
      <WrappedComponent toggleNotification={toggleNotification} {...props} />
    </div>
  );
};

export default withNotification;
