import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import DateTime from 'react-datetime';
import moment from 'moment';
import { Card, CardHeader, CardBody } from 'reactstrap';
import Services from '../../services/service';
import LogoVinaa from '../../assets/img/logo/logo.png';
import { RESPONSE } from '../../config/constants';

const { SUCCESS, FAILED } = RESPONSE;

const RegForm = ({ toggleNotification, eventData }) => {
  const [state, setState] = useState({
    showInvoice: false,
    formResponse: {
      invoiceNumber: '',
      email: '',
      name: '',
      parentName: '',
      parentPhone: '',
      isVerified: false,
    },
    pageError: false,
  });

  const [genderOptions, setGenderOptions] = useState([]);
  useEffect(() => {
    const getGenderOptions = async () => {
      const param = { param: 'gender' };
      await Services.getList(param)
        .then((res) => {
          setGenderOptions(res);
        })
        .catch((err) => console.log(err));
    };

    getGenderOptions();
  }, []);

  const [riderClassOptions, setRiderClassOptions] = useState([]);
  useEffect(() => {
    const getRiderClassOptions = async () => {
      const param = { param: 'rider_class' };
      await Services.getList(param)
        .then((res) => {
          setRiderClassOptions(res);
        })
        .catch((err) => console.log(err));
    };

    getRiderClassOptions();
  }, []);
  
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [isSafeToReset, setIsSafeToReset] = useState(false);
  useEffect(() => {
    if (!isSafeToReset) return;
    reset('', {
      keepValues: false,
    });
    setIsSafeToReset(false);
  }, [reset, isSafeToReset]);

  const onSubmit = async (data) => {
    // console.log('data', data);
    const {
      email,
      name,
      nameOnPlate,
      numStart,
      teamName,
      hometown,
      dob,
      pob,
      gender,
      riderClass,
      birthCertificate,
      parentName,
      parentPhone,
      paymentProof,
    } = data;

    const payload = {
      eventId: eventData._id,
      email,
      name,
      nameOnPlate,
      numStart,
      teamName,
      hometown,
      dob,
      pob,
      gender,
      riderClass,
      birthCertificate: birthCertificate[0],
      parentName,
      parentPhone,
      paymentProof: paymentProof[0],
    };

    // console.log('payload', payload);
    try {
      const res = await Services.postParticipant(payload);
      // console.log('res', res);
      toggleNotification(SUCCESS, res.message);
      setState((prevState) => ({
        ...prevState,
        formResponse: res.data,
        showInvoice: true,
      }));
      setIsSafeToReset(true);
    } catch (error) {
      console.log('error register participant', error);
      if (error.response.data && error.response.data.error) {
        toggleNotification(FAILED, error.response.data.error);
      } else {
        toggleNotification(FAILED, error.response.statusCode);
      }
    }
  };

  const onError = (errors) => {
    console.log(errors);
    toggleNotification(
      FAILED,
      'Ada data yang salah / belum lengkap. Silahkan cek kembali.'
    );
  };

  const { showInvoice, formResponse } = state;

  return (
    <>
      {showInvoice ? (
        <div className="fs-15px">
          {/* <h4>Registrasi Berhasil</h4>
           */}
          <Card>
            <CardHeader>
              <label>
                <strong>Invoice Ticket</strong>
              </label>
              <br />
              {formResponse.invoiceNumber}
            </CardHeader>
            <CardBody>
              <div className="mb-2">
                <label>
                  <strong>Email</strong>
                </label>
                <br />
                {formResponse.email}
              </div>
              <div className="mb-2">
                <label>
                  <strong>Nama</strong>
                </label>
                <br />
                {formResponse.name}
              </div>
              <div className="mb-2">
                <label>
                  <strong>Nama Orang Tua</strong>
                </label>
                <br />
                {formResponse.parentName}
              </div>
              <div className="mb-2">
                <label>
                  <strong>No Whatsapp Orang Tua</strong>
                </label>
                <br />
                {formResponse.parentPhone}
              </div>
              <div className="mb-2">
                <label>
                  <strong>Status Pembayaran</strong>
                </label>
                <br />
                {formResponse.isVerified ? (
                  <span className="badge bg-success fs-12px">Selesai</span>
                ) : (
                  <span className="badge bg-warning fs-12px">
                    Proses Verifikasi
                  </span>
                )}
              </div>
              {!formResponse.isVerified && (
                <div className="mt-3 fs-12px">
                  <label>
                    <strong>Notes</strong>
                  </label>
                  <br />* Email kepesertaan dengan <strong>QR Code</strong> akan
                  dikirim setelah diverifikasi oleh panitia. Mohon menunggu.
                  Terima kasih.
                </div>
              )}
            </CardBody>
          </Card>
          <div className="mt-3 text-inverse text-center">
            Klik{' '}
            <Link
              to="#"
              className="text-danger"
              onClick={() => setState({ ...state, showInvoice: false })}
            >
              disini
            </Link>{' '}
            untuk registrasi baru.
          </div>
        </div>
      ) : (
        <div className="fs-13px">
          <div className="mb-3">
            <label className="mb-2">
              Email <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control fs-13px ${
                errors.email ? 'is-invalid' : ''
              }`}
              // placeholder="Alamat Email"
              {...register(`email`, {
                required: 'Email harus diisi.',
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Masukkan email yang valid.',
                },
              })}
            />
            <div className="invalid-feedback">{errors.email?.message}</div>
          </div>
          <div className="mb-3">
            <label className="mb-2">
              Nama Rider <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control fs-13px ${
                errors.name ? 'is-invalid' : ''
              }`}
              // placeholder="Nama Rider"
              {...register(`name`, {
                required: 'Nama Rider harus diisi.',
                minLength: {
                  value: 3,
                  message: 'Minimal 3 karakter.',
                },
              })}
            />
            <div className="invalid-feedback">{errors.name?.message}</div>
          </div>
          <div className="mb-3">
            <label className="mb-2">
              Nama Rider di Number Plate <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control fs-13px ${
                errors.nameOnPlate ? 'is-invalid' : ''
              }`}
              // placeholder="Nama Rider di Number Plate"
              {...register(`nameOnPlate`, {
                required: 'Nama Rider di Number Plate harus diisi.',
                minLength: {
                  value: 3,
                  message: 'Minimal 3 karakter.',
                },
              })}
            />
            <div className="invalid-feedback">
              {errors.nameOnPlate?.message}
            </div>
          </div>
          <div className="mb-3">
            <label className="mb-2">
              Nomor Start <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control fs-13px ${
                errors.numStart ? 'is-invalid' : ''
              }`}
              // placeholder="Nomor Start"
              onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
              {...register(`numStart`, {
                required: 'Nomor Start harus diisi.',
              })}
            />
            <div className="invalid-feedback">{errors.numStart?.message}</div>
          </div>
          <div className="mb-3">
            <label className="mb-2">
              Nama Team <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control fs-13px ${
                errors.teamName ? 'is-invalid' : ''
              }`}
              // placeholder="Nama Team"
              {...register(`teamName`, {
                required: 'Nama Team harus diisi.',
                minLength: {
                  value: 3,
                  message: 'Minimal 3 karakter.',
                },
              })}
            />
            <div className="invalid-feedback">{errors.teamName?.message}</div>
          </div>
          <div className="mb-3">
            <label className="mb-2">
              Kota Asal <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control fs-13px ${
                errors.hometown ? 'is-invalid' : ''
              }`}
              // placeholder="Kota Asal"
              {...register(`hometown`, {
                required: 'Kota Asal harus diisi.',
                minLength: {
                  value: 3,
                  message: 'Minimal 3 karakter.',
                },
              })}
            />
            <div className="invalid-feedback">{errors.hometown?.message}</div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label className="mb-2">
                  Tanggal Lahir <span className="text-danger">*</span>
                </label>
                <Controller
                  control={control}
                  name={`dob`}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field }) => (
                    <DateTime
                      renderInput={(props) => (
                        <>
                          <input
                            {...props}
                            className={`form-control fs-13px ${
                              errors.dob ? 'is-invalid' : ''
                            }`}
                            onKeyDown={(e) => e.preventDefault()}
                          />
                          <div className="invalid-feedback">
                            Tanggal lahir harus diisi.
                          </div>
                        </>
                      )}
                      inputProps={{ placeholder: 'DD/MM/YYYY', ref: field.ref }}
                      dateFormat="DD/MM/YYYY"
                      timeFormat={false}
                      closeOnSelect={true}
                      isValidDate={(current) => current.isBefore(Date.now())}
                      initialViewDate={moment().subtract(2, 'year')}
                      value={new Date(field.value)}
                      onChange={(date) => {
                        field.onChange(date._d.toISOString());
                      }}
                    />
                  )}
                />
              </div>
              <div className="col-md-6">
                <label className="mb-2">
                  Tempat Lahir <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className={`form-control fs-13px ${
                    errors.pob ? 'is-invalid' : ''
                  }`}
                  // placeholder="Tempat Lahir"
                  {...register(`pob`, {
                    required: 'Tempat Lahir harus diisi.',
                    minLength: {
                      value: 3,
                      message: 'Minimal 3 karakter.',
                    },
                  })}
                />
                <div className="invalid-feedback">{errors.pob?.message}</div>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <label className="mb-2">
              Jenis Kelamin <span className="text-danger">*</span>
            </label>
            <select
              className={`form-control fs-13px ${
                errors.gender ? 'is-invalid' : ''
              }`}
              type="select"
              {...register(`gender`, {
                required: 'Jenis Kelamin harus dipilih.',
              })}
            >
              <option value=""> [ Pilih ] </option>
              {genderOptions.length > 0 &&
                genderOptions.map((data, idx) => {
                  return (
                    <option key={idx} value={data.id}>
                      {data.name}
                    </option>
                  );
                })}
            </select>
            <div className="invalid-feedback">{errors.gender?.message}</div>
          </div>
          <div className="mb-3">
            <label className="mb-2">
              Kelas Rider <span className="text-danger">*</span>
            </label>
            <select
              className={`form-control fs-13px ${
                errors.riderClass ? 'is-invalid' : ''
              }`}
              type="select"
              {...register(`riderClass`, {
                required: 'Kelas Rider harus dipilih.',
              })}
            >
              <option value=""> [ Pilih ] </option>
              {riderClassOptions.length > 0 &&
                riderClassOptions.map((data, idx) => {
                  return (
                    <option key={idx} value={data.id}>
                      {data.name}
                    </option>
                  );
                })}
            </select>
            <div className="invalid-feedback">{errors.riderClass?.message}</div>
          </div>
          <div className="mb-3">
            <label className="mb-2">
              Akta Kelahiran <span className="text-danger">*</span>
            </label>
            <input
              type="file"
              className={`form-control fs-13px ${
                errors.birthCertificate ? 'is-invalid' : ''
              }`}
              accept=".jpg, .jpeg, .png"
              // placeholder="Akta Kelahiran"
              {...register(`birthCertificate`, {
                required: 'File akta kelahiran harus dipilih.',
                validate: {
                  lessThan1MB: files => files[0]?.size < 5000000 || 'Max 5MB',
                  acceptedFormats: files =>
                    ['image/jpeg', 'image/png'].includes(
                      files[0]?.type
                    ) || 'Hanya file PNG, JPG, atau JPEG',
                },
              })}
            />
            <div className="invalid-feedback">
              {errors.birthCertificate?.message}
            </div>
          </div>
          <div className="mb-3">
            <label className="mb-2">
              Nama Orang Tua <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control fs-13px ${
                errors.parentName ? 'is-invalid' : ''
              }`}
              // placeholder="Nama Orang Tua"
              {...register(`parentName`, {
                required: 'Nama Orang Tua harus diisi.',
                minLength: {
                  value: 3,
                  message: 'Minimal 3 karakter.',
                },
              })}
            />
            <div className="invalid-feedback">{errors.parentName?.message}</div>
          </div>
          <div className="mb-3">
            <label className="mb-2">
              Nomor Whatsapp Orang Tua <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              maxLength={13}
              className={`form-control fs-13px ${
                errors.parentPhone ? 'is-invalid' : ''
              }`}
              // placeholder="Nomor Whatsapp Orang Tua"
              onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
              {...register(`parentPhone`, {
                required: 'Nomor Whatsapp Orang Tua harus diisi.',
                minLength: {
                  value: 9,
                  message: 'Minimal 9 karakter.',
                },
                maxLength: {
                  value: 13,
                  message: 'Maksimal 13 karakter.',
                },
              })}
            />
            <div className="invalid-feedback">
              {errors.parentPhone?.message}
            </div>
          </div>
          <div className="mb-3">
            <label>
              Bukti Bayar <span className="text-danger">*</span>
            </label>
            <label className="mb-2">
              Pendaftaran ke nomor bank BCA atas nama Rika Wulandari <strong>7810084388</strong>
            </label>
            <input
              type="file"
              className={`form-control fs-13px ${
                errors.paymentProof ? 'is-invalid' : ''
              }`}
              accept=".jpg, .jpeg, .png"
              // placeholder="Bukti Bayar"
              {...register(`paymentProof`, {
                required: 'File bukti bayar harus dipilih.',
                validate: {
                  lessThan1MB: files => files[0]?.size < 5000000 || 'Max 5MB',
                  acceptedFormats: files =>
                    ['image/jpeg', 'image/png'].includes(
                      files[0]?.type
                    ) || 'Hanya file PNG, JPG, atau JPEG',
                },
              })}
            />
            <div className="invalid-feedback">
              {errors.paymentProof?.message}
            </div>
          </div>
          <div className="mb-4">
            <button
              className="btn btn-danger d-block w-100 btn-lg h-45px fs-13px"
              onClick={() => handleSubmit(onSubmit, onError)()}
            >
              Register
            </button>
          </div>
        </div>
      )}
      <hr className="bg-gray-600 opacity-2" />
      <p className="text-center text-gray-600">
        &copy; Vinaa All Right Reserved 2022
      </p>
      <div className="text-center">
        <img src={LogoVinaa} alt="logo_vinaa" width={'64px'} />
      </div>
    </>
  );
};

export default RegForm;
