import React from 'react';
import Register from '../pages/register/Register';
import Error404 from '../pages/error/error-404';
const routes = [
  {
    path: '/',
    exact: true,
    title: 'Home',
    component: () => <Error404 />
  },
  {
    path: '/:event',
    exact: true,
    title: 'Home',
    component: () => <Register />
  },
];


export default routes;